import type { WindowBootstrapSettings } from 'owa-worker-types';
import { unblockLazyLoadCallbacks } from 'owa-bundling-light';
import { setWindowData } from 'owa-window-data';
import { WorkerWindowData } from 'owa-worker-shared';
import { overrideGetMetatags } from 'owa-metatags';
import { mark } from 'owa-data-worker-utils/lib/initTimings';
import { patchComlink } from 'owa-comlink-patch';
import { overrideSessionId } from 'owa-config/lib/getSessionId';
import { setThreadName } from 'owa-thread-config';
import { setWorkerScriptLoadTimings } from './setWorkerScriptLoadTimings';
import { patchChunkLoad } from '../util/patchChunkLoad';

let bootstrapCalled = false;

// bootstrap is the first thing called by the client -- it should be as lightweight as possible and require as few synchronous imports as possible
export const bootstrap = (rawData: WindowBootstrapSettings, sessionid = '') => {
    // the window data needs to be set ASAP in boot. don't even call initTimings. really.
    // bootstrap can be called with partial window data.  update the window data on the worker
    // if it's the first execution of bootstrap or if the call has full window data
    if (!bootstrapCalled || isFullWindowData(rawData)) {
        const windowData = new WorkerWindowData(rawData);
        setWindowData(windowData);
        overrideGetMetatags(windowData.metatags);
    }

    // preload JS files before calling importScripts
    patchChunkLoad();

    if (sessionid) {
        overrideSessionId(sessionid);
    }

    // these things only need to be done once
    if (!bootstrapCalled) {
        // Set the thread name for the current thread
        setThreadName('DATA_WORKER_THREAD');

        // this needs to run after window data is set
        mark('Bootstrap_WS');

        patchComlink();

        // on main, we block lazy loading until render completes.  there's no render on the worker, so do it now
        unblockLazyLoadCallbacks();

        // Record telemetry for the evaluation timings of worker script load.
        setWorkerScriptLoadTimings();

        mark('Bootstrap_WE');

        bootstrapCalled = true;
    }
};

function isFullWindowData(rawData: WindowBootstrapSettings) {
    // eslint-disable-next-line no-restricted-properties -- worker boostrap needs to set localStorage on the worker data
    return !!rawData.localStorage;
}
