import { LazyBootModule, LazyImport } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "DataWorkerOfflineResolvers" */ './lazyFunctions'),
    { name: 'DataWorkerOfflineResolvers' }
);

export const lazyOfflineResolvers = new LazyImport(lazyModule, m =>
    m.lazyOfflineResolvers.import()
);
