import { isOneOf } from './isOneOf';
import { isNonOwaError } from './isNonOwaError';
import { EmptyErrorStack } from 'owa-trace';
import type { TraceErrorObject } from 'owa-trace';

const knownExternalStrings: string[] = [
    'https://swc.cdn.skype.com',
    'https://acdn.adnxs.com/',
    'https://s.aolcdn.com',
    '/resources/suiteux-shell/',
    'lpcres.delve.office.com/',
    'webshell.suite.office.com',
    'jac.yahoosandbox.com',
    '/owa.vendors~Prague.js',
    '/owa.gfh-itemsview.js',
    // This error means that ResizeObserver was not able to deliver all
    // observations within a single animation frame. It is benign
    'ResizeObserver loop ',
    'Unable to decode audio data',
    'gcm_sender_id not found in manifest',
    'at checkUnread (<anonymous>:',
    'moz-extension://',
    'ms-browser-extension://',
    'Unhandled Rejection: AbortError: The operation was aborted.',
    'https://goo.gl/',
    'AppNexus namespace blocked',
    'Unknown script code',
    'electron/js2c',
    'debugger eval code',
    'midgard/versionless',
    'amcdn.msftauth.net',
    'Telemetry is not configured!',
    'Request failed with status code',
    'Failed to get auth token from Loki',
    'exception thrown during Write operation: WCC: HTTP',
    'Body is disturbed or locked',
    'Request to url "/api/v1/topic/capabilities" failed to complete',
    'AbortError: Registration failed - push service error',
    "Cannot read properties of null (reading 'CodeMirror')",
    // This exception is thrown by dexie for reasons beyond our control (client hard drive is full)
    'QuotaExceededError Encountered full disk while opening backing store',
    // https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/227207
    "Cannot read properties of undefined (reading 'type')",
    // This comes from a browser extension: https://github.com/sereneblue/chameleon
    'getWhitelistFonts',
    // This comes from Floodgate. See https://dev.azure.com/outlookweb/Outlook%20Web/_workitems/edit/303110
    'CommonFloodgate is not initialized',
    // This is thrown by Firefox when an add-on (outside our control) accesses a DOM element that
    // the page has GC'd. https://blog.mozilla.org/addons/2012/09/12/what-does-cant-access-dead-object-mean/
    "can't access dead object",
    "ReferenceError: Can't find variable: $",
    // MCAS proxy
    '.mcas.ms',
    // This is related to Google search app used on iOS Chromium browsers (outside our control). Kusto data also confirms this only happens to iOS.
    // https://issuetracker.google.com/issues/396043331?pli=1
    "ReferenceError: Can't find variable: gmo",
];

const knownExternalStringsWithNoStack: string[] = [
    // these errors come from the suite header and give no call stack
    'Unhandled Rejection: Already Initialized',
    '{"status":',
    '{"state":"error"}',
    '{"isCanceled":true}',
    '{"callCount":',
    '{"resultCode":2,"isAdalException":false,"_typeSpec":"TokenRetrievalError"}',
    '{"ok":false,"status":0,',
    '{}',
    "'window' is not defined",
    "'window' is undefined",
    ',"name":"ShellException"',
    '{"line":0,"column":0}',
    'Unhandled Rejection: {"ResourceName":"otellogging","Details":7}',
    // This means that unhandledrejection was called was no arguments.
    // There is nothing that we can do with this so marking it as external
    'Unhandled Rejection: [no reason given]',
    'SecurityError',
    'Live Persona Card',
    'midgardError',
    'Uncaught Request failed statusCode: 500',
    'user-script',
    'Failed writing data to the file system',
    // This error is most likely happening due to the safe links being scanned by Outlook, as mentioned here - https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552.
    'Object Not Found Matching Id',
    "NotAllowedError: Failed to execute 'read' on 'Clipboard': Read permission denied",
    'Unhandled Rejection: Error: Permission denied',
    'Unhandled Rejection: Request validation failed',
    'Unhandled Rejection: AbortError: The user aborted a request',
    // Caused by MCAS, we don't have cookies in the worker
    "AnalyticsWorker:Uncaught TypeError: Cannot read properties of undefined (reading 'cookie')",
];
export function isExternalError(message: string, error: TraceErrorObject | undefined): boolean {
    if (isOneOf(knownExternalStrings, message)) {
        return true;
    }
    return (
        !!error &&
        (error.external ||
            isNonOwaError(error.stack) ||
            isOneOf(knownExternalStrings, error.stack) ||
            (isEmptyCallstack(<string>error.stack) &&
                isOneOf(knownExternalStringsWithNoStack, message || error.message)))
    );
}

function isEmptyCallstack(stack: string) {
    return /\S/.test(stack) || stack == EmptyErrorStack;
}
