/**
 * These functions can be used before flights are initialized and should not contain any flight checks.
 */

/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Need to use Dexie.exists
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import { doesDatabaseExistInStorageBucket } from 'owa-storage-buckets';
import { BUCKET_NAME, DATABASE_NAME } from './constants';

export function doesLegacyDbExist(): Promise<boolean> {
    return Dexie.exists(DATABASE_NAME);
}

export function doesBucketDbExist(): Promise<boolean> {
    return doesDatabaseExistInStorageBucket(BUCKET_NAME, DATABASE_NAME);
}

export async function doesAnyDbExist(): Promise<boolean> {
    const [exists, existsLegacy] = await Promise.all([doesBucketDbExist(), doesLegacyDbExist()]);
    return exists || existsLegacy;
}
