import type { LocationData, LocalStorageData, WindowData, LocWindow } from 'owa-window-data';
import type {
    WindowBootstrapSettings,
    LocalStorageBootstrapSettings,
    CoreWindowRequestSettings,
} from 'owa-worker-types';
import { wrapForcedLayout } from 'owa-performance';
import { WorkerLocationData } from './WorkerLocationData';
import {
    setLocalStorageItem,
    removeLocalStorageItem,
    type LocalStorageKeys,
} from 'owa-local-storage';

class WorkerStorageData implements LocalStorageData {
    _localStorage: LocalStorageBootstrapSettings;

    constructor(l: LocalStorageBootstrapSettings) {
        this._localStorage = l;
    }

    getItem(key: string) {
        return this._localStorage[key];
    }

    setItem(key: string, value: string) {
        const currentValue = this._localStorage[key];
        if (currentValue === value) {
            return; // remove unnecersary callback to main thread
        }

        setLocalStorageItem(key as LocalStorageKeys, value);
        this._localStorage[key] = value;
    }

    removeItem(key: string) {
        removeLocalStorageItem(key as LocalStorageKeys);
        delete this._localStorage[key];
    }

    clear() {
        throw new Error('cannot clear local storage on a worker');
    }
}

export class WorkerWindowData implements WindowData {
    _window!: WindowBootstrapSettings;
    _location!: LocationData;
    _localStorage!: WorkerStorageData;

    constructor(w: WindowBootstrapSettings) {
        this.updateWindowSettings(w);
    }

    get location() {
        return this._location;
    }

    get innerHeight() {
        return wrapForcedLayout(
            'WorkerWindowData_innerHeight',
            () =>
                /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                 * This is baseline exception, if you edit this file you need to fix this exception.
                 *	> 'innerHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                this._window.innerHeight
        );
    }

    get innerWidth() {
        return wrapForcedLayout(
            'WorkerWindowData_innerWidth',
            () =>
                /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                 * This is baseline exception, if you edit this file you need to fix this exception.
                 *	> 'innerWidth' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                this._window.innerWidth
        );
    }

    get metatags() {
        return this._window.metatags;
    }

    get document() {
        return null;
    }

    get isPwa() {
        return this._window.isPwa;
    }

    get cookie() {
        return this._window.cookie;
    }

    get localStorage() {
        return this._localStorage;
    }

    get origin() {
        return this._window.origin;
    }

    get _locStrings() {
        return (<LocWindow>(<any>self))._locStrings;
    }

    updateWindowSettings(w: WindowBootstrapSettings): void {
        this._window = w;
        this._location = new WorkerLocationData(w.location);
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'localStorage' is restricted from being used. Use owa-local-storage instead. */
        this._localStorage = new WorkerStorageData(w.localStorage);
    }

    updateRequestSettings(w: CoreWindowRequestSettings): void {
        this._window = {
            ...this._window,
            ...w,
        };

        this._location = new WorkerLocationData(w.location);
    }
}
