import type { AnalyticsOptions } from 'owa-analytics-types';
import { getAnalyticsFlightsAndAppSettings } from 'owa-analytics-core/lib/settings/getAnalyticsFlightsAndAppSettings';
import calculateTiming from '../utils/calculateTiming';
import { setErrorHandler } from 'owa-exception-handler';
import { getCachedInfo, stopCachingInfo, isPageTimingSupported } from 'owa-analytics-start';
import { logUsage } from '../api/logUsage';
import { logCoreUsage } from '../api/logCoreUsage';
import { logGreyError } from '../api/logGreyError';
import { logCoreGreyError } from '../api/logCoreGreyError';
import { registerTracerListener, TraceLevel, onGlobalError, trace } from 'owa-trace';
import { logErrorDatapoint } from '../api/internal/logErrorDatapoint';
import { logTraceDatapoint } from '../api/internal/logTraceDatapoint';
import { isAnyTracingEnabled } from '../utils/isTracingEnabled';
import { lazySetupDevTracing } from 'owa-devtools';
import { getAnalyticsAddon } from 'owa-analytics-addons';
import { getThreadName } from 'owa-thread-config';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';

export async function setGlobalAnalyticsListeners(
    analyticsOptions: AnalyticsOptions,
    completionCallback?: () => void
) {
    const info = getCachedInfo(logUsage, logCoreUsage, logGreyError, logCoreGreyError);
    trace.info(
        ` Set Global Analytics Listeners in ${getThreadName()}. IsModuleSwitch: ${
            analyticsOptions?.isModuleSwitch
        }`,
        'analytics'
    );

    // if no aria tokens are passed in, there is no point
    // Do not reset window events if it's a module switch
    if (!analyticsOptions.isModuleSwitch) {
        // the errors are backfilled since the beginning of the app. So we can set the error handler here
        // since this is the first time that we can log the errors
        setErrorHandler(onGlobalError);

        const setCaptureAssetsOpticsAddon = getAnalyticsAddon('SetShouldCaptureAssets');

        if (setCaptureAssetsOpticsAddon.isRegistered) {
            setCaptureAssetsOpticsAddon?.executeNow(
                !!getAnalyticsFlightsAndAppSettings()?.shouldCaptureAssets
            );

            if (getThreadName() === 'MAIN_THREAD' && isPageTimingSupported()) {
                const timing = calculateTiming(self.performance.timing, 'indexPage');
                if (timing) {
                    getAnalyticsAddon('CaptureAssetsOptics')?.executeNow([timing]);
                }
            }
        }

        for (const networkArgs of info.network) {
            getAnalyticsAddon<Promise<void>>('CaptureServiceActionOptics')?.executeNow(
                ...networkArgs
            );
        }
        for (const usage of info.usage) {
            /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops, owa-custom-rules/no-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Baseline, this function can't be used inside a loop, please move it outside
             * undefined
             *	> Function 'logUsage' matches forbidden pattern (/logUsage/) and should not be used inside loops
             (https://aka.ms/OWALintWiki)
                         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logUsage(usage.name, usage.customData, usage.options);
        }
        for (const usage of info.coreUsage) {
            /* eslint-disable-next-line owa-custom-rules/forbid-specific-functions-patterns-inside-loops, owa-custom-rules/no-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Baseline, this function can't be used inside a loop, please move it outside
             * undefined
             *	> Function 'logCoreUsage' matches forbidden pattern (/logCoreUsage/) and should not be used inside loops
             (https://aka.ms/OWALintWiki)
                         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logCoreUsage(usage.name as AnalyticsCoreEventNames, usage.customData, usage.options);
        }
        for (const greyError of info.greyError) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logGreyError(greyError.name, greyError.error, greyError.customData, greyError.options);
        }
        for (const coreGreyError of info.coreGreyError) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logCoreGreyError(
                coreGreyError.name as AnalyticsCoreEventNames,
                coreGreyError.error,
                coreGreyError.customData,
                coreGreyError.options
            );
        }
        registerTracerListener(
            'GlobalAnalytics',
            (message, traceLevel, _component, errorDetails) => {
                if (traceLevel == TraceLevel.Error) {
                    logErrorDatapoint(message, errorDetails);
                }
                if (isAnyTracingEnabled()) {
                    logTraceDatapoint(message, traceLevel);
                }
            },
            !isAnyTracingEnabled() /* listenToErrorsOnly */
        );

        trace.info(
            ` Completed Setting Global Analytics Listeners in ${getThreadName()}`,
            'analytics'
        );
    }

    if (getAnalyticsFlightsAndAppSettings()?.devToolsEnabled && console) {
        // Initialize the actual tracer and process the cached traces
        await lazySetupDevTracing.importAndExecute().catch(() => {
            /** no-op */
        });
    }

    // Stop caching the info
    stopCachingInfo();

    // Only initialize the stress test addon if it is enabled
    if (process.env.ENABLE_ANALYTICS_STRESS_TEST) {
        getAnalyticsAddon('StressTest')?.executeNow();
    }

    completionCallback?.();
}
