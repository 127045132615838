import { addCustomData } from 'owa-data-worker-utils/lib/initTimings';
import { hasQueryStringParameter } from 'owa-querystring';

const performanceMarkQueryStringParam = 'pm';

// Set the worker script load timings
export function setWorkerScriptLoadTimings() {
    const workerScriptLoadStart = self.performance?.getEntriesByName(
        'WorkerEntry_LS'
    )[0] as PerformanceResourceTiming;
    const workerScriptLoadEnd = self.performance?.getEntriesByName(
        'WorkerEntry_LE'
    )[0] as PerformanceResourceTiming;

    if (workerScriptLoadStart && workerScriptLoadEnd) {
        // If the performanceMarkQueryStringParam is present, measure the time it took to load the worker script
        if (hasQueryStringParameter(performanceMarkQueryStringParam)) {
            self.performance?.measure('DataWorkerLoad', 'WorkerEntry_LS', 'WorkerEntry_LE');
        }
        // Add custom data for the worker script load timings
        addCustomData({
            DataWorker_LS: workerScriptLoadStart.startTime,
            DataWorker_LE: workerScriptLoadEnd.startTime,
        });
    }
}
