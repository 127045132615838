import type { UpdateAnalyticsCachesInWorker } from 'owa-analytics-types';
import { updateCommonPropertiesCache } from 'owa-analytics-core/lib/settings/getCommonProperties';
import { updateFlightsAndApplicationSettingsCache } from 'owa-analytics-core/lib/settings/getAnalyticsFlightsAndAppSettings';
import { updatePartialCachedSettings } from 'owa-analytics-core/lib/settings/getCachedSettings';

export function updateAnalyticsCachesInWorker(cachesToUpdate: UpdateAnalyticsCachesInWorker) {
    // Update the FlightsAndApplicationSettings Cache
    if (cachesToUpdate.FlightsAndApplicationSettingsCache) {
        updateFlightsAndApplicationSettingsCache(cachesToUpdate.FlightsAndApplicationSettingsCache);
    }

    // Update the UserConfiguration Cache
    if (cachesToUpdate.UserConfigurationCache) {
        updatePartialCachedSettings({ userConfiguration: cachesToUpdate.UserConfigurationCache });
    }

    // Update the CommonProperties Cache
    if (cachesToUpdate.CommonPropertiesCache) {
        updateCommonPropertiesCache(cachesToUpdate.CommonPropertiesCache);
    }
}
